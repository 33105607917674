import {NgModule} from "@angular/core";
import {ApiService} from "./api.service";

@NgModule({
  declarations: [],
  exports: [],
  imports: [],
  providers: [
    ApiService
  ]
})
export class ApiModule {}
