import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserState} from "./user.models";
import {Store} from "@ngrx/store";
import * as userActions from "./user.actions";

@Injectable({ providedIn: 'root'})
export class UserService {

  /** Observable containing the user state **/
  public state$: Observable<UserState>;

  constructor(private store: Store<UserState>){
    this.state$ = this.store.select((state: any) => state.userState);
  }

  /**
   * Dispatches an get user to store
   */
  public getUser() {
    this.store.dispatch(new userActions.Load());
  }
}
