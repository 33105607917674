import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {routes} from './app.routes';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {CustomAuthConfig, HttpLoaderFactory} from "./app.config";
import {AuthConfig, AuthInterceptor, AuthModule} from "@bisnode/angular-auth";
import {SharedModule} from "./shared/shared.module";
import {UserModule} from "./core/users/user.module";
import {AppErrorComponent} from "./app-error.component";

@NgModule({
  declarations: [
    AppComponent,
    AppErrorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthModule,
    UserModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(routes, {useHash: true, enableTracing: false}),
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: AuthConfig,
      useFactory: CustomAuthConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
