import {ModuleWithProviders, NgModule} from "@angular/core";
import {ApiModule} from "../api/api.module";
import {UserService} from "./user.service";
import {UserGuard} from "./user.guard";
import {StoreModule} from "@ngrx/store";
import {reducer} from "./user.reducers";
import {EffectsModule} from "@ngrx/effects";
import {UserEffects} from "./user.effects";

@NgModule({
  imports: [
    ApiModule,
    StoreModule.forFeature('userState', reducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  declarations: [],
  providers: [
    UserService,
    UserGuard
  ]
})
export class UserModule {}

