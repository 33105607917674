import {Component} from '@angular/core';
import {AuthService} from '@bisnode/angular-auth';
import {filter, take} from 'rxjs/operators';
import {UserService} from './core/users/user.service';
import {LanguageService} from './core/language.service';
import {APPLICATION_LANGUAGES} from './app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public loggedIn = false;

  constructor(private auth: AuthService, private language: LanguageService, public user: UserService) {

    this.language.init(APPLICATION_LANGUAGES);

    this.user.state$.pipe(
      filter(state => !!state.user),
      take(1)
    ).subscribe(state => {
      this.language.validateDefaultLanguageIsAllowed(state.user.country);
    });

    this.auth.getToken().pipe(
      filter(token => !!token),
      take(1)
    ).subscribe(() => this.loggedIn = true);
  }

  /**
  * Logout user
  */
  onLogout(): void {
    this.auth.redirectToLogout();
  }
}
