import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

@Component({
  template: `
    <div class="u-text-center">
      <h1 class="display-1">{{error}}</h1>
      {{('ERROR.' + error) | translate}}
    </div>
  `
})
export class AppErrorComponent {

  public error: number;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.pipe().subscribe(({status}) => {
      this.error = status || 404;
    })
  }

}

