import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {IUser} from "./api.models";

@Injectable()
export class ApiService {

  constructor(private http: HttpClient) {}

  getUsers():  Observable<IUser> {
    return this.http.get<IUser>('api/users')
  }

  generateReport({configuration}) {
    const headers = new HttpHeaders()
      .set('Accept', 'application/pdf')
      .set('Content-Type', 'application/json');
    return this.http.post('api/reports', configuration, {headers, responseType: 'blob'})
  }

  generateLogotype({configuration}) {
    return this.http.post('api/logotypes', configuration, {responseType: 'text'})
  }

  exportLogotype({configuration}) {
    return this.http.post('api/exports', configuration, {observe: 'response'})
  }
}
