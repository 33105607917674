import {NgModule} from "@angular/core";
import {
  CardModule,
  FormModule,
  GridModule,
  IconFieldModule,
  NavigationModule,
  SpinnerModule
} from "@bisnode/components";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormModule,
    GridModule,
    FormsModule,
    CardModule,
    ReactiveFormsModule,
    TranslateModule,
    IconFieldModule,
    NavigationModule,
    SpinnerModule
  ],
  exports: [
    CommonModule,
    FormModule,
    GridModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IconFieldModule,
    NavigationModule,
    SpinnerModule
  ]
})
export class SharedModule {}
