import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LanguageService {

  private defaultLanguage = 'EN';
  private languages: string[] = [];


  constructor(private translate: TranslateService) {}


  public init(languages: string[]) {

    this.defaultLanguage = this.translate.getBrowserLang();
    this.languages = languages;

    this.translate.setDefaultLang(this.translate.getBrowserLang());
    this.translate.addLangs(this.languages);
    this.setupApplicationLanguage(this.translate.getBrowserLang());
  }


  /**
   * Switch languages
   *
   * @param {string} lang
   */
  public switchLanguage(lang: string) {
    this.setupApplicationLanguage(lang);
  }

  /**
   * Get current language
   *
   * @returns {string}
   */
  public get currentLang(): string {
    return this.translate.currentLang;
  }

  public validateDefaultLanguageIsAllowed(country: string) {
    if (country === 'DK' && this.defaultLanguage !== 'da' ||
        country === 'FI' && this.defaultLanguage !== 'fi' ||
        country === 'NO' && this.defaultLanguage !== 'no' ||
        country === 'SE' && this.defaultLanguage !== 'sv' ||
        country === 'HU' && this.defaultLanguage !== 'hu' ||
        country === 'SI' && this.defaultLanguage !== 'sl') {
      this.defaultLanguage = 'en';
      this.translate.setDefaultLang('en');
      this.setupApplicationLanguage(this.defaultLanguage);
    }
  }

  /**
   * Setup user application language, default to 'en'
   *
   * @param lang a string language code
   */
  private setupApplicationLanguage(lang: string): void {

    if (this.languages.indexOf(lang) === -1) {
      lang = this.defaultLanguage;
    }

    this.translate.use(lang);
  }
}
