import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {UserService} from "./user.service";
import {of, Observable} from "rxjs";
import {filter, switchMap, take, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";


@Injectable({ providedIn: 'root'})
export class UserGuard implements CanActivate {

  constructor(private user: UserService, private router: Router) {}

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.user.state$
      .pipe(
        tap(state => {
          if (state.isReady === false && !state.isPending) {
            this.user.getUser();
          }
        }),
        filter(state => !!state.isReady),
        take(1),
        switchMap((state) => {

          const error: any = state.error;
          const unauthorizedError = error && error.status === 401;

          if (error && !unauthorizedError) {
            const status = !(error instanceof HttpErrorResponse) ? 500 : error.status;
            this.router.navigate(['error'], {queryParams: {status: status}});
            return of(false);
          }

          return of(true);
        })
      )
  }
}
