import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Observable, of} from "rxjs";
import {Action} from "@ngrx/store";
import * as userAction from "./user.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {ApiService} from "../api/api.service";

@Injectable()
export class UserEffects {

  @Effect()
  $loadUser: Observable<Action> = this.$actions.pipe(
    ofType(userAction.LOAD),
    switchMap(() => {
      return this.api.getUsers().pipe(
        map((user) => new userAction.LoadSuccess({user})),
        catchError(error => of(new userAction.LoadeError({error})))
      )})
  );

  constructor(private $actions: Actions, private api: ApiService) {}
}
