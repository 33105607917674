import {Action} from "@ngrx/store";

export const LOAD = '[User] - Load user';
export const LOAD_SUCCESS = '[User] - Load user with success';
export const LOAD_ERROR = '[User] - Load user with error';

export class Load implements Action {
  readonly type: string = LOAD;
}

export class LoadSuccess implements Action {
  readonly type: string = LOAD_SUCCESS;
  constructor(public payload: {user}) {}
}

export class LoadeError implements Action {
  readonly type: string = LOAD_ERROR;
  constructor(public payload: {error}) {}
}

export type Actions =
  Load |
  LoadSuccess |
  LoadeError;
