import {Routes} from "@angular/router";
import {AuthGuard} from "@bisnode/angular-auth";
import {UserGuard} from "./core/users/user.guard";
import {AppErrorComponent} from "./app-error.component";

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, UserGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'configure'
      },
      {
        path: 'configure',
        loadChildren: () => import('./configure-logo/configure-logo.module').then(mod => mod.ConfigureLogoModule),
      },
      {
        path: 'accounts',
        loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule),
      }
    ],
  },
  {
    path: "error",
    component: AppErrorComponent
  },
  {
    path: "**",
    redirectTo: "error"
  }
];
