import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AuthConfig} from "@bisnode/angular-auth";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export function CustomAuthConfig(): AuthConfig {
  const config: AuthConfig = new AuthConfig();
  config.basePath = '/live-rating';
  return config;
}
