<bis-navbar [fixed]="true" [inverse]="true" *ngIf="loggedIn">
  <bis-navbar-content position="left">
    <img [src]="'assets/img/DnBWhite.svg'" height="30">
  </bis-navbar-content>
  <bis-navbar-content position="right">
    <a href="#"
       class="navbar__menu-item icon-field icon-field--vertical icon-field--vertical-md-collapse"
       routerLink="configure"
       routerLinkActive="navbar__menu-item--selected">
      <span class="icon-field__icon icon icon--edit"></span>
      <span class="icon-field__text">{{'NAVIGATION.CONFIGURE' | translate}}</span>
    </a>
    <a href="#"
       class="navbar__menu-item icon-field icon-field--vertical icon-field--vertical-md-collapse"
       routerLink="accounts"
       routerLinkActive="navbar__menu-item--selected">
      <span class="icon-field__icon icon icon--account"></span>
      <span class="icon-field__text">{{'NAVIGATION.ACCOUNT' | translate}}</span>
    </a>
    <a href="#"  (click)="onLogout()"
       class="navbar__menu-item icon-field icon-field--vertical icon-field--vertical-md-collapse">
      <span class="icon-field__icon icon icon--log-out"></span>
      <span class="icon-field__text">  {{'NAVIGATION.LOGOUT' | translate}}</span>
    </a>
  </bis-navbar-content>
</bis-navbar>
<div style="margin-top: 100px;">
  <ng-container *ngIf="(user.state$ | async)?.isPending">
    <bis-spinner></bis-spinner>
  </ng-container>
  <router-outlet></router-outlet>
</div>
