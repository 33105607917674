export const APPLICATION_LANGUAGES = ['sv', 'fi', 'en', 'da', 'no', 'hu', 'sl'];

export const GUIDES = {
  SE: "https://www.dnb.com/sv-se/om-oss/kreditvarderingsmodell-foretag/",
  FI: "https://www.dnb.com/fi-fi/tuotteet/bisnoden-luottoluokitustuotteet/",
  NO: "https://www.dnb.com/no/produkter-og-tjenester/kredittvurderingsprodukter/",
  DK: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo/",
  EN: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo-eng/",
  HU: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo-eng/",
  SI: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo-eng/"
};

export const HOMELINK = {
  SE: "https://www.dnb.com/sv-se/om-oss/kreditvarderingsmodell-foretag/",
  FI: "https://www.dnb.com/fi-fi/tuotteet/bisnoden-luottoluokitustuotteet/",
  NO: "https://www.dnb.com/no/produkter-og-tjenester/kredittvurderingsprodukter/",
  DK: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo/",
  EN: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo-eng/",
  HU: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo-eng/",
  SI: "https://www.dnb.com/da-dk/produkter-og-tjenester/live-rating-logo-eng/"
};
