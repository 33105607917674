import * as actions from "./user.actions";
import {UserState} from "./user.models";

export const initialState: UserState = {
  isReady: false,
  isPending: false,
  user: null,
  error: null
};

export function reducer(state: UserState = initialState, action: actions.Actions) {
  switch (action.type) {
    case actions.LOAD : {
      return {...state, isPending: true}
    }
    case actions.LOAD_SUCCESS : {
      const {user} = (action as actions.LoadSuccess).payload;
      return {...state, isPending: false, user: user, isReady: true}
    }
    case actions.LOAD_ERROR : {
      const {error} = (action as actions.LoadeError).payload;
      return {...state, isPending: false, error: error, isReady: true}
    }
    default: {
      return state;
    }
  }
}
